import React from 'react';
import { Box, Text, Center } from '@chakra-ui/react';

import ImgDivider from '@/assets/border/divider.png';

import WithAnimation from '@components/Common/WithAnimation';
import ViewLocationButton from '@components/Common/Buttons/ViewLocation';
import Image from '@components/Common/LazyImage';

import useShiftTime from '@/hooks/useShiftTime';
import useInvitation from '@/hooks/useInvitation';

import txtWording from './locales';
import useLang from '@hooks/useLang';
import useAlt from '@hooks/useAlt';

import {
  WEDDING_AKAD_TIME,
  WEDDING_AKAD_FULLDATE,
  WEDDING_AKAD_LOC_NAME,
  WEDDING_AKAD_LOC_ROAD,
  WEDDING_RESEPSI_FULLDATE,
  WEDDING_RESEPSI_LOC_NAME,
  WEDDING_RESEPSI_LOC_ROAD,
} from '@/constants';
import { IMG_LOGO_WEDDING } from '@/constants/assets';
import { MUSLIM_INVITATION } from '@/constants/feature-flags';
import BorderFrame from '@components/Common/BorderFrame';
import { BTN_COLOR } from '@/constants/colors';

/**
 * function to render WeddingSectionV3 component
 * @returns {JSX.Element}
 */
function WeddingSection({ ...rest }) {
  const shiftTime = useShiftTime();
  const isInvitation = useInvitation();
  const defaultLang = useLang();
  const lang = defaultLang;
  const alt = useAlt();

  /** Render Wedding Information */
  const renderWeddingInfo = ({ title, desc, date, time, place, placeRoad }) => {
    return (
      <Box textAlign="center" color="mainColorText" textTransform="uppercase" fontSize="sm">
        <WithAnimation>
          <Text
            fontWeight="bold"
            fontSize="xl"
            margin="24px 0"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <Text m="16px 0">{desc}</Text>
          <Text fontWeight="bold">{date}</Text>
          <Text fontWeight="bold">{time}</Text>
          {place && (
            <Text fontWeight="bold" marginTop="12px" dangerouslySetInnerHTML={{ __html: place }} />
          )}
          {placeRoad && <Text fontSize="xs" dangerouslySetInnerHTML={{ __html: placeRoad }} />}
        </WithAnimation>
      </Box>
    );
  };

  return (
    <Box bgColor="bgPrimary" padding="32px" pos={'relative'}>
      <BorderFrame isHeader width="calc(100% - 64px)" />
      <Box {...rest} padding="42px 24px" width="100%" position="relative">
        <BorderFrame height="calc(100% - 84px)" width="calc(100%)" top="42px" left="0" />
        <Box>
          <WithAnimation>
            <Center>
              <Image
                src={IMG_LOGO_WEDDING}
                maxWidth="200px"
                marginTop="-10px"
                paddingBottom="10px"
              />
            </Center>
          </WithAnimation>
          <WithAnimation>
            <Box marginTop="8px" fontFamily="body" textAlign="center" color="mainColorText">
              <Text fontSize="md">
                Any day spent with you is my favourite day. So, today is my new favourite day 🤍
              </Text>
              <Text fontSize="md">- Winnie the Pooh -</Text>
            </Box>
          </WithAnimation>
          {/* Box Reception Information */}
          <Box>
            <Box textAlign="center" color="mainColorText" textTransform="uppercase" fontSize="sm">
              {MUSLIM_INVITATION && (
                <>
                  {renderWeddingInfo({
                    title: txtWording.akadMuslim[lang],
                    desc:
                      alt === '1'
                        ? ''
                        : isInvitation
                        ? txtWording.descAkadInvitationMuslim[lang]
                        : txtWording.descAkadMuslim[lang],
                    date: WEDDING_AKAD_FULLDATE,
                    time: isInvitation ? WEDDING_AKAD_TIME : '',
                    place: WEDDING_AKAD_LOC_NAME,
                    placeRoad: isInvitation ? WEDDING_AKAD_LOC_ROAD : '',
                  })}{' '}
                </>
              )}
              {!MUSLIM_INVITATION && (
                <>
                  {renderWeddingInfo({
                    title: txtWording.akadGeneral[lang],
                    desc: isInvitation
                      ? txtWording.descAkadInvitationGeneral[lang]
                      : txtWording.descAkadGeneral[lang],
                    date: WEDDING_AKAD_FULLDATE,
                    time: isInvitation ? WEDDING_AKAD_TIME : '',
                    place: WEDDING_AKAD_LOC_NAME,
                    placeRoad: isInvitation ? WEDDING_AKAD_LOC_ROAD : '',
                  })}{' '}
                </>
              )}
              {isInvitation && (
                <WithAnimation>
                  <Center>
                    <Image src={ImgDivider} maxWidth="100%" marginTop="24px" />
                  </Center>
                </WithAnimation>
              )}
              {/* Wedding - info */}
              {isInvitation &&
                renderWeddingInfo({
                  title: txtWording.reception[lang],
                  desc: alt === '1' ? '' : txtWording.descReception[lang],
                  date: WEDDING_RESEPSI_FULLDATE,
                  time: shiftTime,
                  place: WEDDING_RESEPSI_LOC_NAME,
                  placeRoad: WEDDING_RESEPSI_LOC_ROAD,
                })}
            </Box>
          </Box>
          {/* BOX Button View Location */}
          {isInvitation && (
            <WithAnimation>
              <Center>
                <ViewLocationButton
                  marginTop="16px"
                  size="sm"
                  fontWeight="normal"
                  bgColor={BTN_COLOR}
                  color="btnMainColor"
                  text={txtWording.location[lang]}
                />
              </Center>
            </WithAnimation>
          )}
          <BorderFrame isFooter left={'0'} />
        </Box>
      </Box>
    </Box>
  );
}

export default React.memo(WeddingSection);
