// import P1 from '@/images/2/1.jpg';
// import P2 from '@/images/2/2.jpg';
// import P3 from '@/images/2/3.jpg';
// import P4 from '@/images/2/4.jpg';
// import P5 from '@/images/2/5.jpg';
// import P6 from '@/images/2/6.jpg';
// import P7 from '@/images/2/7.jpg';
// import P8 from '@/images/2/8.jpg';
// import P9 from '@/images/2/9.jpg';
// import P10 from '@/images/2/10.jpg';
// import P11 from '@/images/2/11.jpg';
// import P12 from '@/images/2/12.jpg';
// import P13 from '@/images/2/13.jpg';
// import P14 from '@/images/2/14.jpg';
// import P15 from '@/images/2/15.jpg';
// import P16 from '@/images/2/16.jpg';

export const photos = [
  {
    original:
      'https://ik.imagekit.io/adelinejune/firhanalya/Gallery/A7L07655.jpg?updatedAt=1697605030558',
  },
  {
    original:
      'https://ik.imagekit.io/adelinejune/firhanalya/Gallery/A7L07643.jpg?updatedAt=1697605030700',
  },
  {
    original:
      'https://ik.imagekit.io/adelinejune/firhanalya/Gallery/A7L07781.jpg?updatedAt=1697605030720',
  },
  {
    original:
      'https://ik.imagekit.io/adelinejune/firhanalya/Gallery/A7L07811.jpg?updatedAt=1697605031031',
  },
  {
    original:
      'https://ik.imagekit.io/adelinejune/firhanalya/Gallery/A7L07783.jpg?updatedAt=1697605030944',
  },
  {
    original:
      'https://ik.imagekit.io/adelinejune/firhanalya/Gallery/A7L07919.jpg?updatedAt=1697605031019',
  },
  {
    original:
      'https://ik.imagekit.io/adelinejune/firhanalya/Gallery/A7L07792.jpg?updatedAt=1697605030916',
  },
  {
    original:
      'https://ik.imagekit.io/adelinejune/firhanalya/Gallery/A7L07837.jpg?updatedAt=1697605031037',
  },
  {
    original:
      'https://ik.imagekit.io/adelinejune/firhanalya/Gallery/A7L07826.jpg?updatedAt=1697605031044',
  },
  {
    original:
      'https://ik.imagekit.io/adelinejune/firhanalya/Gallery/A7L07823.jpg?updatedAt=1697605031029',
  },
  {
    original:
      'https://ik.imagekit.io/adelinejune/firhanalya/Gallery/A7L08036.jpg?updatedAt=1697605034083',
  },
  {
    original:
      'https://ik.imagekit.io/adelinejune/firhanalya/Gallery/A7L08144.jpg?updatedAt=1697605034358',
  },
  {
    original:
      'https://ik.imagekit.io/adelinejune/firhanalya/Gallery/A7L08056.jpg?updatedAt=1697605034379',
  },
  {
    original:
      'https://ik.imagekit.io/adelinejune/firhanalya/Gallery/A7L08222.jpg?updatedAt=1697605034421',
  },
  {
    original:
      'https://ik.imagekit.io/adelinejune/firhanalya/Gallery/A7L08153.jpg?updatedAt=1697605034438',
  },
  {
    original:
      'https://ik.imagekit.io/adelinejune/firhanalya/Gallery/A7L08089.jpg?updatedAt=1697605034740',
  },
  {
    original:
      'https://ik.imagekit.io/adelinejune/firhanalya/Gallery/A7L08404.jpg?updatedAt=1697605034748',
  },
  {
    original:
      'https://ik.imagekit.io/adelinejune/firhanalya/Gallery/A7L08331.jpg?updatedAt=1697605035066',
  },
  {
    original:
      'https://ik.imagekit.io/adelinejune/firhanalya/Gallery/A7L08324.jpg?updatedAt=1697605035110',
  },
  {
    original:
      'https://ik.imagekit.io/adelinejune/firhanalya/Gallery/A7L08412.jpg?updatedAt=1697605035169',
  },
  {
    original:
      'https://ik.imagekit.io/adelinejune/firhanalya/Gallery/A7L08461.jpg?updatedAt=1697605037968',
  },
  {
    original:
      'https://ik.imagekit.io/adelinejune/firhanalya/Gallery/A7L08476.jpg?updatedAt=1697605038222',
  },
  {
    original: 'https://ik.imagekit.io/adelinejune/firhanalya/bonus.jpg?updatedAt=1697095886494',
    // thumbnail: P1,
  },
  {
    original: 'https://ik.imagekit.io/adelinejune/firhanalya/bonus2.jpg?updatedAt=1697096185302',
    // thumbnail: P2,
  },
  // {
  //   original: P3,
  //   // thumbnail: P3,
  // },
  // {
  //   original: P4,
  //   // thumbnail: P4,
  // },
  // {
  //   original: P5,
  //   // thumbnail: P5,
  // },
  // {
  //   original: P6,
  //   // thumbnail: P6,
  // },
  // {
  //   original: P7,
  //   // thumbnail: P7,
  // },
  // {
  //   original: P8,
  //   // thumbnail: P8,
  // },
  // {
  //   original: P9,
  //   // thumbnail: P9,
  // },
  // {
  //   original: P10,
  //   // thumbnail: P10,
  // },
  // {
  //   original: P11,
  //   // thumbnail: P11,
  // },
  // {
  //   original: P12,
  //   // thumbnail: P12,
  // },
  // {
  //   original: P13,
  //   // thumbnail: P13,
  // },
  // {
  //   original: P14,
  //   // thumbnail: P14,
  // },
  // {
  //   original: P15,
  //   // thumbnail: P15,
  // },
  // {
  //   original: P16,
  //   // thumbnail: P16,
  // },
  // {
  //   original: P17,
  //   // thumbnail: P17,
  // },
  // {
  //   original: P18,
  //   // thumbnail: P18,
  // },
  // {
  //   original: P19,
  //   // thumbnail: P19,
  // },
  // {
  //   original: P20,
  //   // thumbnail: P20,
  // },
  // {
  //   original: P21,
  //   // thumbnail: P21,
  // },
  // // {
  // //   original: P22,
  // //   // thumbnail: P22,
  // // },
  // {
  //   original: P23,
  //   // thumbnail: P23,
  // },
  // {
  //   original: P24,
  //   // thumbnail: P24,
  // },
  // {
  //   original: P25,
  //   // thumbnail: P25,
  // },
  // {
  //   original: P26,
  //   // thumbnail: P26,
  // },
  // {
  //   original: P27,
  //   // thumbnail: P27,
  // },
  // {
  //   original: P28,
  //   // thumbnail: P28,
  // },
  // {
  //   original: P29,
  //   // thumbnail: P29,
  // },
  // {
  //   original: P30,
  //   // thumbnail: P30,
  // },
  // {
  //   original: P31,
  //   // thumbnail: P31,
  // },
  // {
  //   original: P32,
  //   // thumbnail: P32,
  // },
];
