import getQueryValue from '@invitato/helpers/dist/getQueryStringValue';
import { useLocation } from '@reach/router';

function useAlt() {
  const location = useLocation();
  const altType = getQueryValue(location, 'alt');

  let alt;

  if (altType === '1') {
    alt = '1';
  }

  return alt;
}

export default useAlt;
