import get from '@invitato/helpers/dist/getLocale';

export default {
  textDear: get('Kepada Bapak/Ibu/Saudara/i,', 'Dear Mr/Mrs/Ms,'),
  guest: get('Keluarga & Teman-teman', 'Family & Friends'),

  welcomingAnnouncement: get(
    'Sebuah kehormatan bagi kami dapat mengumumkan kabar bahagia pada hari ini. Tanpa mengurangi rasa hormat, karena keterbatasan waktu dan jarak, kami mohon maaf tidak bisa mengundang Bapak/Ibu/Saudara/i secara langsung dalam acara pernikahan kami. Tetapi kami tetap memohon doa restu untuk keberlangsungan acara pernikahan kami.',
    'We are pleased to announce our wedding. Due to the limited capacity, we had decided to invite only families to attend our wedding. However, your prayers and wishes will mean a lot to us. Thank you for your understanding.',
  ),

  welcomingInvitation: get(
    'Sebuah kehormatan bagi kami dapat mengumumkan kabar bahagia pada hari ini. Besar harapannya, Bapak/Ibu/Saudara/i berkenan hadir dan mendoakan secara langsung dalam hari pernikahan kami.',
    'Together with joyful hearts and the grace of God, we cordially request the honor of your presence at our wedding celebration. We are pleased to announce and sincerely hope that you will be able to attend and pray directly on our wedding day. Your presence will mean a lot to us.',
  ),

  welcomingInvitationMuslim: get(
    'Sebuah kehormatan bagi kami dapat mengumumkan kabar bahagia pada hari ini. Besar harapannya, Bapak/Ibu/Saudara/i berkenan hadir dan mendoakan secara langsung dalam hari pernikahan kami.',
    `Together with the blessing of Allah Subhanahu wa Ta'ala, we cordially request the honor of your presence at our wedding celebration. We are pleased to announce and sincerely hope that you will be able to attend and pray directly on our wedding day. Your presence will mean a lot to us.`,
  ),

  parentsInvitation: get(
    'Sebuah kehormatan dapat mengumumkan kabar bahagia pada hari ini. Besar harapannya, Bapak/Ibu/Saudara/i berkenan hadir dan mendoakan secara langsung dalam hari pernikahan putra-putri kami.',
    'Together with joyful hearts and the grace of God, we cordially request the honor of your presence at our wedding celebration. We are pleased to announce and sincerely hope that you will be able to attend and pray directly on our wedding day. Your presence will mean a lot to us.',
  ),
};
