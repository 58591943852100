import React from 'react';
import { string, bool } from 'prop-types';
import { Button, IconButton } from '@chakra-ui/react';
import { FaInstagram } from 'react-icons/fa';
import { FiTwitter } from 'react-icons/fi';
import { TEXT_PRIMARY } from '@/constants/colors';

function InstagramButton({ colorScheme, onlyIcon, id, text, socialMedia, ...rest }) {
  const handleRedirect = () => {
    if (!id) return '';

    if (socialMedia === 'instagram') {
      window.open(`https://instagram.com/${id}`, '_blank');
    } else if (socialMedia === 'twitter') {
      window.open(`https://twitter.com/${id}`, '_blank');
    }
  };

  const commonProps = {
    colorScheme,
    onClick: handleRedirect,
  };

  if (!id) return null;

  if (onlyIcon) {
    return (
      <IconButton
        isRound
        aria-label="instagram icon"
        icon={<FaInstagram color={TEXT_PRIMARY} />}
        {...commonProps}
        {...rest}
      />
    );
  }

  return (
    <Button
      leftIcon={socialMedia === 'instagram' ? <FaInstagram /> : <FiTwitter />}
      variant="solid"
      size="xs"
      borderRadius="8px"
      padding="8px 16px"
      {...commonProps}
      {...rest}
    >
      {text}
    </Button>
  );
}

InstagramButton.propTypes = {
  colorScheme: string,
  id: string,
  onlyIcon: bool,
  text: string,
  socialMedia: string,
};

InstagramButton.defaultProps = {
  colorScheme: 'button.secondaryDark',
  id: '',
  onlyIcon: false,
  text: 'Instagram',
  socialMedia: 'instagram',
};

export default InstagramButton;
