import get from '@invitato/helpers/dist/getLocale';

export default {
  nameayat: get('[Matius 19:6]', '[Matthew 19:6]'),
  ayat: get(
    'Demikianlah mereka bukan lagi dua, melainkan satu. Karena itu, apa yang telah dipersatukan Allah, tidak boleh diceraikan manusia.”',
    'So they are no longer two, but one flesh. Therefore what God has joined together, let no one separate.”',
  ),

  nameayatquran: get('[HR. Al Baihaqi]', '[HR. Al Baihaqi]'),
  ayatquran: get(
    '“Jika seseorang menikah, maka ia telah menyempurnakan separuh agamanya. Karenanya, bertakwalah pada Allah pada separuh yang lainnya.”',
    '“Jika seseorang menikah, maka ia telah menyempurnakan separuh agamanya. Karenanya, bertakwalah pada Allah pada separuh yang lainnya.”',
  ),
};
