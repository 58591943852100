import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import ImageHover from '@components/Common/ImageHover';
import WithAnimation from '@components/Common/WithAnimation';

import txtWording from './locales';
import useLang from '@hooks/useLang';

import { IMG_WELCOME_1, IMG_WELCOME_2, IMG_WELCOME_3, IMG_WELCOME_4 } from '@/constants/assets';
import { MUSLIM_INVITATION } from '@/constants/feature-flags';

/**
 * If you want change images,
 * just change this constants
 */
const IMAGES = [IMG_WELCOME_1, IMG_WELCOME_2, IMG_WELCOME_3, IMG_WELCOME_4];

function QuranSection({ ...rest }) {
  const defaultLang = useLang();
  const lang = defaultLang;

  return (
    <Box bgColor="bgPrimary">
      <ImageHover images={IMAGES} />
      {/* quran */}
      <WithAnimation>
        <Box fontSize="md" padding="32px" paddingTop="52px" color="mainColorText" {...rest}>
          {!MUSLIM_INVITATION && <Text textAlign="center">{txtWording.ayat[lang]}</Text>}
          {!MUSLIM_INVITATION && (
            <Text marginTop="16px" fontWeight="bold" textAlign="center">
              {txtWording.nameayat[lang]}
            </Text>
          )}
          {MUSLIM_INVITATION && (
            <Text textAlign="center">
              إِذَا تَزَوَّجَ العَبْدُ فَقَدْ كَمَّلَ نَصْفَ الدِّيْنِ ، فَلْيَتَّقِ اللهَ فِي
              النِّصْفِ البَاقِي <br></br> {txtWording.ayatquran[lang]}
            </Text>
          )}
          {MUSLIM_INVITATION && (
            <Text marginTop="16px" fontWeight="bold" textAlign="center">
              {txtWording.nameayatquran[lang]}
            </Text>
          )}
        </Box>
      </WithAnimation>
    </Box>
  );
}

export default React.memo(QuranSection);
